<template>
  <div class="login">
    <div
      class="login-image"
      :style="{ 'background-image': 'url(' + getImageLink() + ')' }"
    ></div>
    <v-container class="login-contaier" fill-height fluid>
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="display-1 font-weight-thin mb-4">
            Ваше пространство карт
          </h1>
          <h4 class="subheading">
            Войдите или зарегистрируйтесь, чтобы начать
          </h4>
          <v-btn @click="login" color="orange lighten-2 text-white mt-7"
            >Вход / Регистрация</v-btn
          >
          <v-btn
            :to="{ name: 'public-maps' }"
            color="orange lighten-2 text-white mt-7 ml-md-5 ml-sm-5 ml-xs-0"
            >Посмотреть примеры</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { onLogin } from "../../vue-apollo";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export default {
  name: "login",
  data() {
    return {
      auth: getAuth(),
    };
  },
  methods: {
    login() {
      this.auth.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          return firebaseUser
            .getIdToken()
            .then((token) =>
              this.auth.currentUser.getIdTokenResult().then((result) => {
                if (result.claims["https://hasura.io/jwt/claims"]) {
                  return token;
                }
                const endpointRefreshToken =
                  "https://us-central1-mapdev-io.cloudfunctions.net/refreshToken";
                return fetch(
                  `${endpointRefreshToken}?uid=${firebaseUser.uid}`
                ).then((res) => {
                  if (res.status === 200) {
                    return firebaseUser.getIdToken(true);
                  }
                  return res.json().then((e) => {
                    throw e;
                  });
                });
              })
            )
            .catch(console.error);
        }
      });
      const provider = new GoogleAuthProvider();
      signInWithPopup(this.auth, provider)
        .then((result) => {
          const user = result.user;

          if (user) {
            this.auth.currentUser.getIdToken().then((result) => {
              onLogin(this.$apollo, result);
            });
            const endpointAddUser =
              "https://us-central1-mapdev-io.cloudfunctions.net/addUser";
            return fetch(
              `${endpointAddUser}?uid=${user.uid}&email=${user.email}`
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getImageLink() {
      let w = document.body.clientWidth;
      let h = Math.max(window.innerHeight, document.body.clientHeight);
      return `https://picsum.photos/${w}/${h}/?grayscale`;
    },
  },

  mounted() {
    // скрыть скролл
    document.documentElement.style = "overflow: hidden";
  },

  beforeDestroy() {
    // показать скролл
    document.documentElement.style = "";
  },
};
</script>

<style lang="scss">
// .login::after {
.login-image {
  display: block;
  content: "";
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  // background-image: url('https://picsum.photos/1920/1080/?grayscale');
  background-size: cover;
  z-index: 1;
}
.login::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.315);
}
.login {
  color: white;
  height: 100vh;
}
.login-contaier {
  position: relative;
  z-index: 5;
}
</style>
